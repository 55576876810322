import { onboarder } from '@/characters/onboarder'
import { ALL_PREMADE_CHARACTERS } from '@/characters/PremadeCharacter'
import { monotonicFactory, ulid } from 'ulidx'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface Msg {
  id: string
  role: 'user' | 'assistant' | 'system'
  content: string
  isStartConversation?: boolean
}
export type Convo = {
  id: string
  characterCursor: string
  characterIsTyping: boolean
  characterId: string
  messages: Msg[]
}
export interface Char {
  id: string
  name: string
  systemPrompt: string
  avatarId?: string
  initialMessages?: string
}

interface ConversationStore {
  me: {
    id: string
    name: string
  } | null
  myKeypair: {
    publicKey: string
    privateKey: string
  } | null
  serverPublicKey: string | null
  characters: { [characterId: string]: Char }
  conversations: { [conversationId: string]: Convo }

  updateCharacter: (characterId: string, updater: Partial<Char>) => void
  setPublicKey: (publicKey: string) => void
  createCharacter: (character: Char) => void
  getLatestConversation: (characterId: string) => Convo | null
  clearCharacterConversation: (characterId: string) => void
  setCharacterIsTyping: (conversationId: string, isTyping: boolean) => void
  updateCharacterCursor: (conversationId: string, cursor: string) => void
  getConversation: (conversationId: string) => Convo | null
  getLastCharacterConversation: (characterId: string) => Convo | null
  getCharacter: (characterId: string) => Char | null
  addMessageToConversation: (conversationId: string, message: Msg) => void
  initialize: (template: string, meId: string) => void
  updateMe: (updater: Partial<{ name: string }>) => void
  clearAllMessagesAfter: (id: string) => void
}

export const useConversationStore = create(
  persist<ConversationStore>(
    (set, get) => ({
      me: {
        id: ulid(),
        name: 'anon',
      },
      myKeypair: null,
      serverPublicKey: null,
      characters: {},
      conversations: {},
      activeCharacterId: null,
      initialize: (template: string, meId: string) => {
        const me = get().me || {
          id: meId,
          name: 'anon',
        }
        me.id = meId
        set({ me })
        const characters = get().characters
        if (Object.keys(characters).length > 0) {
          return
        }
        const premadeCharacter = ALL_PREMADE_CHARACTERS[template] || onboarder
        const character: Char = {
          id: ulid(),
          name: premadeCharacter.name,
          systemPrompt: premadeCharacter.systemPrompt,
          initialMessages: premadeCharacter.initialMessages,
        }

        const keypair = get().myKeypair
        if (!keypair) {
          // Generate a new RSA keypair using Web Crypto API
          crypto.subtle
            .generateKey(
              {
                name: 'RSA-OAEP',
                modulusLength: 2048,
                publicExponent: new Uint8Array([1, 0, 1]),
                hash: { name: 'SHA-256' },
              },
              true, // extractable
              ['encrypt', 'decrypt']
            )
            .then(keyPair => {
              // Export the public key to PEM format
              crypto.subtle.exportKey('spki', keyPair.publicKey).then(exportedPublicKey => {
                const publicKeyBase64 = btoa(String.fromCharCode(...new Uint8Array(exportedPublicKey)))
                const publicKeyPEM = `-----BEGIN PUBLIC KEY-----\n${publicKeyBase64}\n-----END PUBLIC KEY-----`

                // Export the private key to PKCS#8 format
                crypto.subtle.exportKey('pkcs8', keyPair.privateKey).then(exportedPrivateKey => {
                  const privateKeyBase64 = btoa(String.fromCharCode(...new Uint8Array(exportedPrivateKey)))
                  const privateKeyPEM = `-----BEGIN PRIVATE KEY-----\n${privateKeyBase64}\n-----END PRIVATE KEY-----`

                  // Store both keys in the store
                  set({
                    myKeypair: {
                      publicKey: publicKeyPEM,
                      privateKey: privateKeyPEM, // Store the private key as a PEM string
                    },
                    characters: { ...get().characters, [character.id]: character },
                  })
                })
              })
            })
        }

        // set({  })
      },
      setPublicKey: (publicKey: string) => {
        set({ serverPublicKey: publicKey })
      },
      setCharacterIsTyping: (conversationId, isTyping) => {
        const conversations = get().conversations
        conversations[conversationId] = {
          ...conversations[conversationId],
          characterIsTyping: isTyping,
        }
        set({ conversations })
      },
      updateCharacter: (characterId, updater) => {
        const characters = get().characters
        characters[characterId] = {
          ...characters[characterId],
          ...updater,
        }
        set({ characters })
      },
      updateCharacterCursor: (conversationId, cursor) => {
        const conversations = get().conversations
        conversations[conversationId] = {
          ...conversations[conversationId],
          characterCursor: cursor,
        }
        set({ conversations })
      },
      clearCharacterConversation: (characterId: string) => {
        const latestConvo = get().getLatestConversation(characterId)
        if (!latestConvo) {
          return
        }
        // remove the messages from the conversation
        const conversations = get().conversations
        conversations[latestConvo.id] = {
          ...conversations[latestConvo.id],
          messages: [],
          characterIsTyping: false,
          characterCursor: '',
        }
        set({ conversations })
      },
      createCharacter: (character: Char) => {
        set({ characters: { ...get().characters, [character.id]: character } })
      },
      getConversation: (conversationId: string) => {
        return get().conversations[conversationId] ?? null
      },
      getLastCharacterConversation: (characterId: string) => {
        const character = get().getCharacter(characterId)!

        const u = monotonicFactory()

        const initialMessages = character.initialMessages
          ? character.initialMessages
              .split('\n')
              .filter(Boolean)
              .map(
                m =>
                  ({
                    id: u(),
                    role: 'assistant',
                    content: m,
                  }) as const
              )
          : []

        const conversations = get().conversations
        const entries = Object.entries(conversations)
        const filtered = entries.filter(([id, convo]) => convo.characterId === characterId)
        if (filtered.length === 0) {
          const newConvo: Convo = {
            id: ulid(),
            characterId,
            characterIsTyping: false,
            characterCursor: '',
            messages: initialMessages,
          }
          set({ conversations: { ...get().conversations, [newConvo.id]: newConvo } })
          return newConvo
        }
        const sorted = filtered.sort((a, b) => a[0].localeCompare(b[0]))
        const entry = sorted[sorted.length - 1]
        const convo = entry[1]
        if (convo) {
          return convo
        }
        const newConvo: Convo = {
          id: ulid(),
          characterId,
          characterIsTyping: false,
          characterCursor: '',
          messages: initialMessages,
        }
        set({ conversations: { ...get().conversations, [newConvo.id]: newConvo } })
        return newConvo
      },
      getLatestConversation: characterId => {
        const conversationIds = Object.keys(get().conversations)
        if (conversationIds.length === 0) return null
        const sorted = conversationIds.sort((a, b) => a.localeCompare(b))
        for (let i = sorted.length - 1; i >= 0; i--) {
          const convoId = sorted[i]
          const convo = get().conversations[convoId]
          if (convo?.characterId === characterId) {
            return convo
          }
        }
        return null
      },
      getCharacter: characterId => get().characters[characterId] ?? null,
      addMessageToConversation: (conversationId, message) => {
        const conversations = get().conversations
        conversations[conversationId] = {
          ...conversations[conversationId],
          messages: [...conversations[conversationId].messages, message],
        }
        set({ conversations })
      },
      updateMe: updater => {
        const me = get().me
        if (!me) return
        set({ me: { ...me, ...updater } })
      },
      clearAllMessagesAfter: (id: string) => {
        const conversations = get().conversations
        Object.values(conversations).forEach(convo => {
          convo.messages = convo.messages.filter(m => m.id < id)
        })
        set({ conversations: Object.fromEntries(Object.entries(conversations)) })
      },
    }),
    {
      name: 'conversation-store',
      // storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
)

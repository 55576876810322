import { emma } from '@/characters/emma'
import { julia } from '@/characters/julia'
import { marcus } from '@/characters/marcus'
import { onboarder } from '@/characters/onboarder'
import { palpatine } from '@/characters/palpatine'
import { sarah } from '@/characters/sarah'
import { sophia } from '@/characters/sophia'

export interface PremadeCharacter {
  name: string
  systemPrompt: string
  avatarId?: string
  exampleResponses?: string
  initialMessages?: string
}

export type PremadeCharacters = Record<string, PremadeCharacter | undefined>

export const ALL_PREMADE_CHARACTERS: PremadeCharacters = {
  marcus,
  sophia,
  julia,
  emma,
  sarah,
  palpatine,
  onboarder,
}

import { useEffect, useState } from 'react'

type Device = 'mobile' | 'desktop'

export const useDevice = (): Device => {
  const [deviceType, setDeviceType] = useState<Device>('desktop')

  useEffect(() => {
    const checkDevice = () => {
      if (typeof window === 'undefined') return 'desktop'

      const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera
      const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

      return mobileRegex.test(userAgent.toLowerCase()) ? 'mobile' : 'desktop'
    }

    setDeviceType(checkDevice())
  }, [])

  return deviceType
}

import type { MeFragment } from '@/graphql/generated/query.types';
import React from 'react';

// For convenience, we use a non-nullable type since most of the application requires an authenticated user.
// When the user context has an undefined user, the application will redirect to login and force authentication
// the rest of the application can render / receive a valid UserContext.
export const UserContext = React.createContext<MeFragment & {
  name: string;
}>(undefined as any);
// https://reactjs.org/docs/context.html#contextdisplayname
UserContext.displayName = 'UserContext';
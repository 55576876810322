// import type { Operator } from '@repo/db/schema/Operator'
import type { UserFragment } from '@/graphql/generated/query.types'
import * as Sentry from '@sentry/nextjs'
import { jwtDecode } from 'jwt-decode'
import { create } from 'zustand'

interface AuthStoreState {
  accessToken: string
  requiresLogin: boolean
  // operator: Operator | null
  login: (accessToken: string) => void
  logout: () => void
}

export const __unsafe_useAuthStore = create<AuthStoreState>((set, get) => {
  return {
    accessToken: '',
    requiresLogin: false,
    operator: null,

    login: accessToken => {
      const decoded = accessToken ? jwtDecode<UserFragment>(accessToken) : null
      const scope = Sentry.getCurrentScope()
      if (decoded) {
        scope.setUser({
          ...decoded,
          id: decoded.id,
        })
      } else {
        scope.clear()
      }
      set({
        accessToken,
        // operator: decoded,
        requiresLogin: Boolean(accessToken),
      })
    },
    logout: () => {
      fetch('/api/logout', { method: 'POST' })
      set({ accessToken: '', requiresLogin: true })
      Sentry.getCurrentScope().clear()
    },
  }
})

export const APP_SYNC_API_KEY = 'da2-jypbxwmkabhglia7q2p7e2f7l4'
export const APP_SYNC_HTTP_DOMAIN = 'gqvujmgg3bflxp7eiemsxrlhk4.appsync-api.us-west-2.amazonaws.com'
export const APP_SYNC_REALTIME_DOMAIN = 'gqvujmgg3bflxp7eiemsxrlhk4.appsync-realtime-api.us-west-2.amazonaws.com'

export const APP_SYNC_AUTHORIZATION = { 'x-api-key': APP_SYNC_API_KEY, host: APP_SYNC_HTTP_DOMAIN }

export function getAppSyncAuthProtocol() {
  const header = btoa(JSON.stringify(APP_SYNC_AUTHORIZATION))
    .replace(/\+/g, '-') // Convert '+' to '-'
    .replace(/\//g, '_') // Convert '/' to '_'
    .replace(/=+$/, '') // Remove padding `=`
  return `header-${header}`
}

import { AuthWrapper } from '@/components/auth/AuthWrapper';
import { apolloClient } from '@/graphql/apollo';
import { useDevice } from '@/hooks/useDevice';
import { cn } from '@/lib/utils';
import '@/styles/globals.css';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
export default function App({
  Component,
  pageProps
}: AppProps) {
  const device = useDevice();
  if (device === 'mobile') {
    return <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/80">
        <div className="m-4 rounded-lg bg-white p-6 text-center dark:bg-gray-800">
          <h2 className="mb-4 text-xl font-bold">Desktop Only</h2>
          <p className="text-sm">
            This application is only available on desktop devices. Please switch to a desktop device to use the app.
          </p>
        </div>
      </div>;
  }
  return <div className={cn('h-screen w-full overflow-hidden bg-[#e0e0e0] dark:bg-[#1e1e1e] font-sans antialiased')} data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <ThemeProvider attribute="class" defaultTheme="dark" enableSystem data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
        <ApolloProvider client={apolloClient} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.tsx">
          <AuthWrapper data-sentry-element="AuthWrapper" data-sentry-source-file="_app.tsx">
            <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
          </AuthWrapper>
        </ApolloProvider>
      </ThemeProvider>
    </div>;
}
/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: string; output: string; }
};

export type GqlCharacter = {
  __typename?: 'GQLCharacter';
  avatarId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  systemPrompt: Scalars['String']['output'];
  timeCreated: Scalars['DateTime']['output'];
  user?: Maybe<GqlUser>;
};

export type GqlCharacterCreator = {
  avatarId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  systemPrompt: Scalars['String']['input'];
};

export type GqlCharacterUpdater = {
  name?: InputMaybe<Scalars['String']['input']>;
  systemPrompt?: InputMaybe<Scalars['String']['input']>;
};

export type GqlConversation = {
  __typename?: 'GQLConversation';
  character: GqlCharacter;
  characterCursor?: Maybe<Scalars['String']['output']>;
  characterId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isNsfw: Scalars['Boolean']['output'];
  lastMessage?: Maybe<GqlMessage>;
  lastMessageId?: Maybe<Scalars['String']['output']>;
  messages: Array<GqlMessage>;
  timeCreated: Scalars['DateTime']['output'];
  timeEnded?: Maybe<Scalars['String']['output']>;
  user: GqlUser;
  userId: Scalars['String']['output'];
};

export type GqlConversationCreator = {
  characterId: Scalars['String']['input'];
};

export type GqlMessage = {
  __typename?: 'GQLMessage';
  character?: Maybe<GqlCharacter>;
  characterId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  conversationId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isError: Scalars['Boolean']['output'];
  isFlagged: Scalars['Boolean']['output'];
  role: MessageRole;
  timeCreated: Scalars['DateTime']['output'];
  user?: Maybe<GqlUser>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type GqlMessageCreator = {
  characterId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  conversationId: Scalars['String']['input'];
};

export type GqlPresignedUrl = {
  __typename?: 'GQLPresignedUrl';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GqlSearchCharactersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type GqlUser = {
  __typename?: 'GQLUser';
  id: Scalars['String']['output'];
  timeCreated: Scalars['DateTime']['output'];
};

export enum MessageRole {
  assistant = 'assistant',
  start = 'start',
  user = 'user'
}

export type Mutation = {
  __typename?: 'Mutation';
  createCharacter: GqlCharacter;
  createConversation: GqlCharacter;
  createMessage: GqlConversation;
  setIsTyping: Scalars['Boolean']['output'];
  startConversation: GqlConversation;
  updateCharacter: GqlCharacter;
};


export type MutationCreateCharacterArgs = {
  creator: GqlCharacterCreator;
};


export type MutationCreateConversationArgs = {
  creator: GqlConversationCreator;
};


export type MutationCreateMessageArgs = {
  creator: GqlMessageCreator;
};


export type MutationSetIsTypingArgs = {
  conversationId: Scalars['String']['input'];
};


export type MutationStartConversationArgs = {
  characterId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdateCharacterArgs = {
  id: Scalars['String']['input'];
  updater: GqlCharacterUpdater;
};

export type Query = {
  __typename?: 'Query';
  character: GqlCharacter;
  conversation: GqlConversation;
  isTyping: Array<Scalars['String']['output']>;
  me?: Maybe<GqlUser>;
  message: GqlMessage;
  messages: Array<GqlMessage>;
  presignedPutAvatarUrl: GqlPresignedUrl;
  searchCharacters: Array<GqlCharacter>;
  user: GqlUser;
};


export type QueryCharacterArgs = {
  id: Scalars['String']['input'];
};


export type QueryConversationArgs = {
  id: Scalars['String']['input'];
};


export type QueryIsTypingArgs = {
  conversationId: Scalars['String']['input'];
};


export type QueryMessageArgs = {
  id: Scalars['String']['input'];
};


export type QueryMessagesArgs = {
  conversationId: Scalars['String']['input'];
};


export type QueryPresignedPutAvatarUrlArgs = {
  contentType: Scalars['String']['input'];
};


export type QuerySearchCharactersArgs = {
  input: GqlSearchCharactersInput;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type MeFragment = { __typename?: 'GQLUser', id: string };

export type PresignedUrlFragment = { __typename?: 'GQLPresignedUrl', id: string, url: string };

export type UserFragment = { __typename?: 'GQLUser', id: string };

export type FetchMeVariables = Exact<{ [key: string]: never; }>;


export type FetchMe = { __typename?: 'Query', me?: { __typename?: 'GQLUser', id: string } | null };

export type FetchPresignedPutAvatarUrlVariables = Exact<{
  contentType: Scalars['String']['input'];
}>;


export type FetchPresignedPutAvatarUrl = { __typename?: 'Query', presignedPutAvatarUrl: { __typename?: 'GQLPresignedUrl', id: string, url: string } };

export const UserFragment = gql`
    fragment UserFragment on GQLUser {
  id
}
    `;
export const MeFragment = gql`
    fragment MeFragment on GQLUser {
  ...UserFragment
}
    ${UserFragment}`;
export const PresignedUrlFragment = gql`
    fragment PresignedUrlFragment on GQLPresignedUrl {
  id
  url
}
    `;
export const FetchMeDocument = gql`
    query FetchMe {
  me {
    ...MeFragment
  }
}
    ${MeFragment}`;

/**
 * __useFetchMe__
 *
 * To run a query within a React component, call `useFetchMe` and pass it any options that fit your needs.
 * When your component renders, `useFetchMe` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMe({
 *   variables: {
 *   },
 * });
 */
export function useFetchMe(baseOptions?: Apollo.QueryHookOptions<FetchMe, FetchMeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
      }
export function useFetchMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMe, FetchMeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
        }
export function useFetchMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchMe, FetchMeVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
        }
export type FetchMeHookResult = ReturnType<typeof useFetchMe>;
export type FetchMeLazyQueryHookResult = ReturnType<typeof useFetchMeLazyQuery>;
export type FetchMeSuspenseQueryHookResult = ReturnType<typeof useFetchMeSuspenseQuery>;
export type FetchMeQueryResult = Apollo.QueryResult<FetchMe, FetchMeVariables>;
export const FetchPresignedPutAvatarUrlDocument = gql`
    query FetchPresignedPutAvatarUrl($contentType: String!) {
  presignedPutAvatarUrl(contentType: $contentType) {
    ...PresignedUrlFragment
  }
}
    ${PresignedUrlFragment}`;

/**
 * __useFetchPresignedPutAvatarUrl__
 *
 * To run a query within a React component, call `useFetchPresignedPutAvatarUrl` and pass it any options that fit your needs.
 * When your component renders, `useFetchPresignedPutAvatarUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPresignedPutAvatarUrl({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useFetchPresignedPutAvatarUrl(baseOptions: Apollo.QueryHookOptions<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables> & ({ variables: FetchPresignedPutAvatarUrlVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>(FetchPresignedPutAvatarUrlDocument, options);
      }
export function useFetchPresignedPutAvatarUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>(FetchPresignedPutAvatarUrlDocument, options);
        }
export function useFetchPresignedPutAvatarUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>(FetchPresignedPutAvatarUrlDocument, options);
        }
export type FetchPresignedPutAvatarUrlHookResult = ReturnType<typeof useFetchPresignedPutAvatarUrl>;
export type FetchPresignedPutAvatarUrlLazyQueryHookResult = ReturnType<typeof useFetchPresignedPutAvatarUrlLazyQuery>;
export type FetchPresignedPutAvatarUrlSuspenseQueryHookResult = ReturnType<typeof useFetchPresignedPutAvatarUrlSuspenseQuery>;
export type FetchPresignedPutAvatarUrlQueryResult = Apollo.QueryResult<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>;
import { useEffect, useRef, type DependencyList, type EffectCallback } from 'react'

/**
 * A hook that prevents double-execution of effects in React Strict Mode.
 *
 * React Strict Mode intentionally double-invokes effects to help find bugs.
 * This hook ensures the effect only runs once during development, while still
 * properly handling dependency changes.
 *
 * @param effect - The effect to run
 * @param deps - Optional dependency array
 */
export function useEffectUnsafe(effect: EffectCallback, deps?: DependencyList) {
  const initialized = useRef(false)
  const prevDeps = useRef(deps)

  useEffect(() => {
    // Reset if deps actually changed (not from strict mode re-render)
    if (deps && prevDeps.current !== undefined && JSON.stringify(prevDeps.current) !== JSON.stringify(deps)) {
      initialized.current = false
    }

    // Only run once until deps change
    if (!initialized.current) {
      initialized.current = true
      prevDeps.current = deps
      effect()
    }
  }, deps)
}

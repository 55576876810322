import {
  APP_SYNC_AUTHORIZATION,
  APP_SYNC_HTTP_DOMAIN,
  APP_SYNC_REALTIME_DOMAIN,
  getAppSyncAuthProtocol,
} from '@/lib/appSync'
import { useEffect } from 'react'
import { ulid } from 'ulidx'

import { z } from 'zod'

export const StreamStartedEventSchema = z.object({
  type: z.literal('stream_started'),
})

export const StreamEndedEventSchema = z.object({
  type: z.literal('stream_ended'),
})

export const LogsChangedEventSchema = z.object({
  type: z.literal('logs_changed'),
})

export const UserChangedEventSchema = z.object({
  type: z.literal('user_changed'),
})

export const AppSyncEventSchema = z.discriminatedUnion('type', [
  StreamStartedEventSchema,
  StreamEndedEventSchema,
  LogsChangedEventSchema,
  UserChangedEventSchema,
])

export type StreamStartedEvent = z.infer<typeof StreamStartedEventSchema>
export type StreamEndedEvent = z.infer<typeof StreamEndedEventSchema>
export type LogsChangedEvent = z.infer<typeof LogsChangedEventSchema>
export type UserChangedEvent = z.infer<typeof UserChangedEventSchema>
export type AppSyncEvent = z.infer<typeof AppSyncEventSchema>

// https://aws.amazon.com/blogs/mobile/announcing-aws-appsync-events-serverless-websocket-apis/
export function useAppSync(args: { key: string; onMessage: (message: AppSyncEvent) => void; skip?: boolean }) {
  const { key, onMessage, skip } = args

  useEffect(() => {
    if (skip) {
      return
    }

    const socket = new Promise<WebSocket>((resolve, reject) => {
      const socket = new WebSocket(`wss://${APP_SYNC_REALTIME_DOMAIN}/event/realtime`, [
        'aws-appsync-event-ws',
        getAppSyncAuthProtocol(),
      ])
      socket.onopen = () => {
        socket.send(JSON.stringify({ type: 'connection_init' }))
        resolve(socket)
      }
      socket.onclose = evt => reject(new Error(evt.reason))
      socket.onmessage = event => {
        if (event.type === 'message') {
          const parsedData = JSON.parse(event.data as string)
          if (parsedData.type === 'data') {
            const data = AppSyncEventSchema.parse(JSON.parse(parsedData.event))
            console.log('=>', data)
            onMessage(data)
          }
        }
      }
    })

    socket.then(s => {
      s.send(
        JSON.stringify({
          type: 'subscribe',
          id: ulid(),
          channel: `/default/${key}`,
          authorization: APP_SYNC_AUTHORIZATION,
        })
      )
    })

    return () => {
      socket.then(s => s.close())
    }
  }, [key, onMessage, skip])
}

export async function publishAppSyncEvent(key: string, event: AppSyncEvent) {
  await fetch(`https://${APP_SYNC_HTTP_DOMAIN}/event`, {
    method: 'POST',
    headers: APP_SYNC_AUTHORIZATION,
    body: JSON.stringify({
      channel: `/default/${key}`,
      events: [JSON.stringify(event)],
    }),
  })
}

export function getAppSyncKey(args: { userId: string }) {
  const { userId } = args
  return `poke-user-${userId}`
}
